.modal {
  display: block;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8) 50% 50% no-repeat;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
}

.spinner {
  position: relative;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
}

.table-spinner-override {
  margin: 0;
  top: initial;
  left: initial;
}

.running,
.success,
.ok {
  color: #008000;
}

.warning {
  color: #cccc00;
}

.failed,
.error {
  color: red;
}

.blue {
  color: blue;
}

.green {
  color: green;
}

.table-borderless > tbody > tr > td,
.table-borderless > tbody > tr > th,
.table-borderless > tfoot > tr > td,
.table-borderless > tfoot > tr > th,
.table-borderless > thead > tr > td,
.table-borderless > thead > tr > th {
  border: none;
}

.bp-portal-main-buttoncontainer {
  /* border-bottom: 1px solid lightgrey; */
  padding-bottom: 10px;
  padding: 10px;
}

.bp-portal-sidebar {
  grid-area: navigation;
  color: green;
  background: white;
  /*border-right: 1px solid lightgray;*/
  margin: 15px;
  overflow: auto;
}

.separator {
  border-bottom: 1px solid lightgrey;
  margin-right: -320px;
}

table {
  width: 100%;
}

.ellipsis {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bp-table-fixed {
  width: 100%;
  table-layout: fixed;
}

.bp-table-container {
  margin-top: 0px;
}

.msIconExample {
  margin-top: 500px;
  /* font-size: 50px; */
  height: 100px;
  width: 50px;
  margin: 0 25px;
}

/*.menu-table-page {
    display: grid;
    grid-template-areas: "breadcrumbs breadcrumbs" "title title" "menu-table-lhs menu-table-rhs";
    grid-template-columns: 225px 1fr;
    grid-template-rows: 27px 45px 1fr;
    min-height: 100vh;
    height: 100%
}*/

.settingChanged {
  border-right: solid 3px purple;
}

.tiny-column {
  width: 15px;
}

.micro-column {
  width: 3px;
}

.small-column {
  width: 30px;
}

.date-column {
  width: 140px;
}

.width-20 {
  width: 20px;
}

.width-30 {
  width: 30px;
}

.width-40 {
  width: 40px;
}

.width-50 {
  width: 50px;
}

.width-60 {
  width: 60px;
}

.width-70 {
  width: 70px;
}

.width-80 {
  width: 80px;
}

.blade-list-table {
  table-layout: fixed;
  width: 100%;
}

.blade-list-table td {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.bold {
  font-weight: 700 !important;
}

.limitText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.border {
  border: 1px solid green;
}

.env-icon {
  /* font-size: 18px; */
  margin-right: 2px;
}
.env-buttons {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.env-buttons li {
  width: 100%;
  list-style: none;
  background: rgb(40, 35, 35);
  line-height: 30px;
  /*padding-left: 15px;*/
}

.env-buttons li a {
  text-decoration: none;
  color: #ffffff;
  display: block;
  cursor: pointer;
}

.env-buttons li a span {
  padding-right: 5px;
}

.env-buttons li a:hover {
  text-decoration: none;
  background: rgb(50, 45, 45);
}

.selected-button {
  color: green;
  padding: 3px;
  border: 1px lightgray solid;
}

.buttons {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.buttons li {
  width: 100%;
  list-style: none;
  color: rgb(35, 30, 30);
  line-height: 30px;
}

.buttons li a {
  text-decoration: none;
  background: #ffffff;
  display: block;
  cursor: pointer;
}

.buttons li a span {
  padding-right: 5px;
}

.buttons li a:hover {
  text-decoration: none;
  color: rgb(45, 40, 40);
}

.horizontal {
  display: inline;
}

ul.breadcrumb2 {
  margin-top: 3px;
  margin-left: 0px;
  padding-left: 0px;
}

ul.breadcrumb2 li {
  display: inline;
}

ul.breadcrumb2 li + li:before {
  padding: 10px;
  color: black;
  content: '>';
}

ul.breadcrumb2 li a {
  text-decoration: none;
}

ul.breadcrumb2 li a:hover {
  text-decoration: underline;
}

.red {
  background-color: crimson;
}

.hand {
  cursor: pointer;
}

.hide-me {
  display: none;
}

.notification-bar,
.ms-MessageBar-content {
  /* font-size: 20px; */
}

/* .ms-TextField input {
    border: solid 1px rgb(96, 94, 92);
} */

.build-control .form-label {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.build-control .pad4-demo {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 5px;
}

.applicant-details-quote div {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 4px;
  padding-bottom: 4px;
}

/* .pivot-item {
    margin-top: -8px;
    padding-left: 8px;
    padding-right: 8px;
}

.pivot-container button {
    color: #4596b4;
    text-transform: uppercase;
    font-weight: 400;
    padding: 20px !important;
}

.pivot-container .is-selected {
    background: #4596b4;
    color: white;
    text-transform: uppercase;
    font-weight: 400;
    padding-top: 30px !important;
    padding-bottom: 30px !important;
} */
