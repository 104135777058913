.hub-file-explorer {
  padding-block: 0px;
  background-color: var(--surface);
  min-width: 430px;
  max-width: 430px;
  height: 100%;
  padding-top: 10px;
  overflow-y: auto;
}

.hub-file-explorer-loading-container {
  padding: 30px;
  max-width: 140px;
}

.hub-file-explorer-collapsable {
  display: grid;
  grid-template-rows: 0fr;
  padding-inline-start: 10px;
  opacity: 0;
  transition: all 0.1s ease-out;
}

.hub-file-explorer .file-explorer-header {
  background-color: var(--surface);
  margin: 10px 10px 5px 21px;
  font-size: 16px;
  font-weight: var(--font-weight-6);
  border-bottom: 0.1em solid var(--text);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.is-open {
  opacity: 1;
  width: max-content;
  grid-template-rows: 1fr;
}

.hub-file-explorer__inner {
  overflow: hidden;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  padding-inline-start: 5px;
}

.hub-file-explorer__section button {
  position: relative;
  border: none;
  width: 100%;
  background: none;
  /* padding-block: 5px; */
  text-align: left;
  display: flex;
  align-items: center;
}

.hub-file-explorer__section button .hub-file-explorer-file-icon {
  margin-inline-start: 16px;
}

.hub-file-explorer__section button.selectedChild {
  background-color: rgba(0, 0, 0, 0.1);
}

.hub-file-explorer__section .folder-caret {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  text-align: center;
  line-height: 10px;
  transform-origin: center center;
  transition: transform 0.1s ease-in;
}

.hub-file-explorer__section .isActive .folder-caret {
  transform: rotate(90deg);
  transition: transform 0.1s ease-out;
}

.hub-file-explorer__itemlist {
  margin-bottom: 0;
}

.hub-file-explorer > .hub-file-explorer__itemlist {
  padding-block-end: 0;
  margin-block-end: 0;
}

.hub-file-explorer__itemlist li {
  padding-block-start: 2px;
}

.hub-file-explorer__itemlist li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 22px;
  cursor: pointer;
  color: var(--text8);
}

.hub-file-explorer__itemlist li a.active {
  font-weight: bold;
}

.hub-file-explorer-file-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-inline-end: 10px;
}

/* .file-icon-json svg path {
    fill: blue;
} */

.file-icon-yaml .svgIconBG {
  fill: transparent;
}

.file-icon-yaml .svgIcon-path1,
.file-icon-yaml .svgIcon-path2 {
  fill: #d2c001;
}

.file-icon-yaml .svgIcon-path2 {
  fill: #d27801;
}

.file-icon-folder .svgIcon-fill1 {
  transition: fill 0.1s ease-in;
  fill: #efce4a;
}
.file-icon-folder .svgIcon-fill2 {
  transition: fill 0.1s ease-in;
  fill: #ebba16;
}

.isActive .file-icon-folder .svgIcon-fill1 {
  /* fill: #efce4a; */
  transition: all 0.1s ease-out;
  fill: #ebba16;
}

.isActive .file-icon-folder path.svgIcon-fill1 {
  d: path('M 96.5 93.7h-93c-2 0-3.5-1.5-3.5-3.5V45.4h100v54.8c0 2-1.5 3.5-3.5 3.5z');
}

.isActive .file-icon-folder .svgIcon-fill2 {
  transition: fill 0.1s ease-out;
  fill: #efce4a;
}

.isActive .file-icon-folder path.svgIcon-fill2 {
  d: path('M44.9 20V9.7c0-2-1.5-3.5-3.5-3.5h-38C1.5 6.3 0 7.8 0 9.7v35.7h100V23.5c0-2-1.5-3.5-3.5-3.5H44.9z');
}

/* CSS for the popup menu */
.popup-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 4px;
  z-index: 1000;
  width: 150px;
}

.popup-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup-menu ul li {
  padding: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

.popup-menu ul li:hover {
  background-color: #f0f0f0;
}

.popup-menu button {
  margin-top: 10px;
  padding: 5px 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
}

.popup-menu button:hover {
  background-color: #0056b3;
}

.popup-menu .separator {
  height: 1px;
  margin: 1px 0; /* Spacing above and below the separator */
  list-style: none;
}

.popup-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  border-radius: 4px;
  z-index: 1000;
  width: 180px;
}

.popup-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup-menu ul li {
  padding: 8px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  transition: background 0.3s;
}

.popup-menu ul li:hover {
  background-color: #f0f0f0;
}
.popup-menu .disabled {
  color: #999;
  cursor: default;
  font-size: 0.9em;
}

.popup-menu .shortcut {
  color: #999;
  font-size: 0.9em;
}
