.tabs-container {
  display: flex;
  flex-direction: column;
}
ul.tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  height: 40px;
  display: -webkit-box;
}

.tabs {
  /* padding: 8px; */
  margin-top: 8px;
  overflow-y: hidden;
}

.tabs li {
  width: auto;
  cursor: pointer;
  margin-top: 5px;
}

.tabs li div {
  display: flex;
  flex-direction: column;
  margin-left: 6px;
}

.tabs li div:hover {
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}

.tabs li .active {
  font-weight: 700;
  border-bottom: var(--brand) 3px solid;
}

.tabs li span {
  display: block;
  color: rgb(50, 49, 48);
  text-align: center;
  padding: 3px;
  text-decoration: none;
  font-weight: 400;
  /* font-size: 1em; */
  margin: 0 5px;
  float: left;
}

.tabs-contents {
  flex-grow: 1;
  height: Calc(110% - 50px);
  height: 100%;
  padding-top: 0;
  margin-top: 0;
}

ul.vertical-tabs {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  /* width: 350px; */
  display: flex;
  flex-flow: column;
  /* height: 50px; */
  /* display: -webkit-box; */
}
.vertical-tabs {
  /* padding: 8px; */
  margin-top: 8px;
  overflow-y: hidden;
}
.vertical-tabs li {
  width: auto;
  cursor: pointer;
  margin-top: 7px;
}

.vertical-tabs li span {
  display: flex;
  flex-direction: row;
  margin-left: 6px;
}

.vertical-tabs li:hover {
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}

.vertical-tabs li span.active {
  font-weight: 700;
  border-bottom: var(--clr-primary-00) 3px solid;
}

.vertical-tabs li span {
  display: block;
  color: rgb(50, 49, 48);
  text-align: center;
  padding: 6px 6px 6px 6px;
  text-decoration: none;
  font-weight: 400;
  /* font-size: 1em; */
  margin: 0 20px;
  /* float: left; */
  text-align: left;
}

.vertical-tabs-header.errors::before {
  content: '\1F7AD';
  font-family: 'wingdings2';
  /* font-size: 18px; */
  font-weight: 600;
  /* font-weight: normal;
    font-style: normal; */
  margin: 10px 15px 0px 0px;
  text-decoration: none;
  float: left;
  color: rgb(217, 67, 79);
}
.vertical-tabs-header.no-errors::before {
  content: '\1F5F8';
  font-family: 'wingdings2';
  /* font-size: 18px; */
  font-weight: 600;
  /* font-weight: normal;
    font-style: normal; */
  margin: 10px 15px 0px 0px;
  text-decoration: none;
  float: left;
  color: rgb(79, 217, 67);
}

.package-item {
  display: flex;
  align-items: center;
  padding: 0.25em;
  list-style-type: none;
}

.package-item .package-title {
  color: rgb(50, 49, 48);
  font-weight: var(--fw-600);
  /* font-size: 16px; */
  margin-top: 5px;
}

.package-item.package-item.selected .package-title,
.package-item.package-item.selected .package-description {
  color: white;
}

.package-item .package-description {
  color: rgb(50, 49, 48);
  line-height: 2;
  /* font-size: 12px; */
}

.package-container {
  padding: 0;
  margin: 0;
  position: relative;
  height: 100%;
}

.package-item:hover {
  background-color: var(--surface-invert-light);
  color: white;
}

.package-item-selected {
  background-color: var(--surface-invert-light);
  color: white;
}

.package-item-installed {
  opacity: 0.65;
}

.package-image {
  margin: 5px;
  width: 32px;
  height: 32px;
}

.button {
  padding-top: 2em;
  padding-top: 2em;
}

/* .package-item::before {
    content: '';
    position: absolute;

    z-index: 2;
    left: 2em;
    bottom: 0.75em;
    border: 0.5em solid var(--clr-primary-400);
    border-radius: 50%;
} */

p {
  padding: 0;
  margin: 0;
}
/* 
grid-template-columns: repeat(auto-fit, minmax(max-content, 1fr)) minmax(450px 350px) 1em; 
    grid-template-areas:
        'header header header'
        'main side side "
        'footer footer footer';

*/

/*  properties */
.hubProp-col {
  margin: 0;
  padding: 0;
}

.communication-header p {
  text-transform: uppercase;
  /* font-size: 1em; */
  font-weight: 500;
}

/* Divorce */
.hub-html {
  line-height: 1.7;
  /* font-size: 1.25em; */
}

.hub-html img {
  height: 75%;
}
