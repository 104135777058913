.container-expander-header {
    background-color: grey;
    padding: 18px 90px 18px 15px;
    height: 60px;
    cursor: pointer;
    color: white;
    position: relative;
    width: 100%;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 20px;
    border-radius: 5px;
}

.container-expander-body {
    padding: 0px 10px;
    /* border: 1px solid #4596b4; */
    margin-bottom: 25px;
}

.container-expander-header.selected {
    background-color: #4596b4;
}

.container-expander-header.errors::before {
    content: '\1F7AD';
    font-family: 'wingdings2';
    font-weight: 600; /* font-weight: normal;
    font-style: normal; */
    margin: 0px 10px 0px 0px;
    text-decoration: none;
    /* float: right; */
    color: rgb(217, 67, 79);
}

.container-expander-header.no-errors::before {
    content: '\1F5F8';
    font-family: 'wingdings2';
    font-weight: 600;
    /* font-weight: normal;
    font-style: normal; */
    margin: 10px 15px 0px 0px;
    text-decoration: none;
    /* float: right; */
    color: rgb(79, 217, 67);
}

/* &:hover {
    background-color:  ${(props) => areaThemeColor(props.theme, props.themeName)('expanderSelected')};
    color:  ${(props) => areaThemeColor(props.theme, props.themeName)('expanderSelectedText')}
}
&.expanded {
    background-color:  ${(props) => areaThemeColor(props.theme, props.themeName)('expanderSelected')}
    i {
        transform: rotate(180deg);
    }
}
`; */
