.designer-container {
  background-color: white;
  border: 1px solid #e1e4e8;
  border-radius: 5px;
  max-width: 1000px;
  /* height: 900px; */
  /* max-width: 1000px;
                      min-width: 600; */
  margin: 0 auto;
  padding: 15px;
  margin-bottom: 20px;
}

.designer-container .main-section {
  padding: 10px;
  /* background-color: #ccc; */
  /* border: solid 1px #bbb; */
  margin: 10px;
}

.designer-container .main-section h1 {
  color: #0072c6;
  margin: 10px;
  margin-left: 0px;
  margin-top: 0px;
  /* background-color: #ccc; */
}

.designer-container h1,
.designer-container h2,
.designer-container h3,
.designer-container h4 {
  color: #0072c6;
  margin: 0px 0;
  font-weight: normal;
}

.designer-container h1 {
  font-size: 22px;
}

.designer-container h2 {
  font-size: 20px;
  text-decoration: underline;
  text-underline-offset: 6 px;
}

.designer-container h3 {
  font-size: 15px;
}

.designer-container h4 {
  font-size: 12px;
}

.designer-container .property {
  display: flex;
  margin: 0px 0;
}

.designer-container .property-key {
  color: #333;
  min-width: 120px;
}

.designer-container .property-value {
  flex-grow: 1;
}

.designer-container .property-value input {
  width: 100%;
  padding: 1px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 12px;
  flex-basis: 60%;
  font-family: Consolas, 'Courier New';
  flex-grow: 1;
}

.designer-container .endpoint-header h2 {
  margin-bottom: 5px;
}

.designer-container .property-value input,
.designer-container .property-value textarea {
  width: 100%;
  border: none;
  border-radius: 4px;
  font-size: 12x;
  font-family: Consolas, 'Courier New', monospace;
  background-color: #f3f4f6;
  overflow-x: auto; /* Enable horizontal scroll */
  white-space: nowrap; /* Prevent text wrapping */
}

.designer-container .nested-section {
  margin: 8px 0px;
  margin-bottom: 0px;
  margin-right: 1px;
  margin-left: 0px;
  padding-bottom: 0px;
  padding-left: 15px;
  background-color: #fafafa;
  border-left: 0px solid #0072c6;
}

.designer-container .pipe-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -3px;
  margin-top: -5px;
}

.designer-container .comment {
  color: green;
  font-size: 14px;
  min-height: 16px;
}

.designer-container .comment-body {
  color: green;
  font-size: 14px;
}
.designer-container .save-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.designer-container .add-action-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5px 0;
}

.designer-container .add-action {
  background-color: #0078d4;
  color: white;
  border: none;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 0px 0;
}

.designer-container .property-value input:focus {
  border-color: #0078d4;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 120, 212, 0.5);
}

.designer-container .condition-header {
  color: purple;
  font-weight: bold;
  font-size: 15px;
}

.designer-container .continuation-policy,
.designer-container .condition-end {
  color: purple;
  background-color: #fff;
  font-weight: bold;
  font-style: italic;
  margin-top: 6px;
}

.designer-container .condition-body {
  margin-top: 6px;
  margin-bottom: 0px;
  margin-right: 1px;
  padding-bottom: 0px;
  padding-left: 12px;
  background-color: #fafafa;
  border-left: 2px solid purple;
}

.designer-container .pipe-separator {
  border: 1px solid red;
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 0px;
  visibility: hidden;
}

.designer-container .endpoint-section,
.designer-container .pipe-section {
  background-color: white;
  border-radius: 4px;
  border: #ccc 1px solid;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 5px;
  padding-left: 8px;
}

.designer-container .endpoint-section {
  margin-bottom: 15px;
  border-left: 1px solid;
}

.designer-container .pipeline-content {
  border-left: red 1px solid;
  padding: 10px;
  padding-bottom: 0px;
}
.designer-container .comment {
  color: green;
  font-style: italic;
}

.designer-container .add-action-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.designer-container .collapsible-content {
  margin-left: 0px;
  /* padding: 10px; */
  border-left: 0px solid #0078d4;
}

.designer-container .toggle-button {
  background: none;
  border: none;
  font-size: 14px;
  color: #0078d4;
  cursor: pointer;
  padding: 5px;
  margin-left: 8px;
}

.designer-container .toggle-button:hover {
  color: #005ea8;
}

.designer-container .expand-collapse-controls {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.designer-container .expand-collapse-controls button {
  background-color: #0078d4;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.designer-container .expand-collapse-controls button:hover {
  background-color: #005ea8;
}

.designer-container .pipeline-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.designer-container .toggle-button {
  background: none;
  border: none;
  font-size: 14px;
  color: #0078d4;
  cursor: pointer;
  padding: 5px;
  margin-left: auto; /* Pushes the button to the right */
}

.designer-container .toggle-button:hover {
  color: #005ea8;
}

.designer-container .pipe-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.designer-container .action-dropdown select {
  padding: 4px;
  font-size: 12px;
}

.designer-container .comment input {
  flex-grow: 1;
}

.designer-container .comment input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 12px;
  font-family: Consolas, 'Courier New', monospace;
}

.designer-container .comment-dropdown-container {
  display: flex;
  align-items: center;
  gap: 10px; /* Gap between the input and dropdown */
}

.designer-container .comment-dropdown-container input {
  flex: 1; /* Ensures the input takes up available space */
  border: 0px solid #4caf50; /* Green border */
  font-size: 14px;
  color: green; /* Green text */
  font-family: Consolas, 'Courier New', monospace;
}

.designer-container .action-dropdown select {
  padding: 4px;
  font-size: 12px;
  border-radius: 4px;
}

.designer-container .error-pipe h3 {
  /* color: red; */
}

.designer-container .replacements {
  padding-left: 5px;
}
