.justify-start {
    justify-content: flex-start;
}
.justify-center {
    justify-content: center;
}

.justify-bottom {
    justify-content: end;
}

.justify-end {
    justify-content: flex-end;
}

.align-start {
    align-items: flex-start;
    align-content: flex-start;
}
.align-center {
    align-items: center;
}
.align-bottom {
    align-items: flex-end;
}

.align-content-start {
    align-content: flex-start;
}

.flex-row {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 1366px) {
    .row-reverse-xxl {
        flex-direction: row-reverse;
    }
}
@media screen and (min-width: 1024px) {
    .row-reverse-xl {
        display: flex;
        flex-direction: row-reverse;
    }
}
@media screen and (min-width: 640px) {
    .row-reverse-lg {
        flex-direction: row-reverse;
    }
}
@media screen and (min-width: 480px) {
    .row-reverse-md {
        flex-direction: row-reverse;
    }
}
@media screen and (min-width: 0px) {
    .row-reverse-sm {
        flex-direction: row-reverse;
    }
}

disabled-link {
    pointer-events: none;
}

/* .hub-collection {
    width: 100%;
}

.hub-collection-row {
    width: 100%;
} */
