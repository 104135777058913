/* .menu__collection {
    padding: 1228px;
} */
.menu__collection {
  /* line-height: 1.75 !important; */
  padding-inline: 1.5rem;
  width: 100%;
  min-width: 250px;
  padding-top: 0;
  overflow-y: auto;
  /* font-size: 1.5rem; */
}

.menu__collection li {
  list-style-type: none;
}

.menuCollection__link {
  font-weight: var(--font-weight-4);
}

.menuCollection__link:focus {
  outline: none;
  border-radius: 3px;
  padding-left: 2px;
  /* padding: 1px; */
  box-shadow: 0px 0px 0px 1px var(--brand-invert);
}

.menu__collection--light .menuCollection__link:focus {
  outline: none;
  border-radius: 3px;
  padding-left: 2px;
  /* padding: 1px; */
  box-shadow: 0px 0px 0px 1px var(--brand);
}

.menuCollection__items {
  margin: 0;
  padding: 0;
  padding: 0.6rem 0 1rem 0;
  /* font-size: 1em; */
}

.menuCollection__link__image {
  margin: 0.29em 0.63em 0 0;
  height: 1em;
  width: 1em;
}

.menuCollection__title {
  font-weight: var(--font-weight-6);
  white-space: nowrap;
}

.menu__collection--light {
  background-color: var(--surface);
}
.menu__collection--light .menuCollection__link__text,
.menu__collection--light .menuCollection__title {
  color: var(--text);
}
.menu__collection--light .menuCollection__title {
  border-bottom: 0.1em solid var(--text);
}

/* Dark*/
.menu__collection--dark {
  background-color: var(--surface-invert);
}
.menu__collection--dark .menuCollection__link__text,
.menu__collection--dark .menuCollection__title {
  color: var(--text-invert);
}
.menu__collection--dark .menuCollection__title {
  border-bottom: 0.1em solid var(--text-invert);
}
