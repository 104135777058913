#adviser_yourdashboard .layout-title {
}

#adviser_yourdashboard .layout-title {
    color: rgb(65, 60, 60);
    padding-left: 0px;
    margin-top: 50px;
    padding-top: 40px !important;
    /* font-size: 22px; */
}

.link-url {
    color: rgb(65, 60, 60);
    cursor: pointer;
    text-decoration: underline;
    /* font-size: xx-large; */
    font-weight: 700;
}

.ms-DetailsHeader {
    padding-top: 0px;
}

.toggle,
.form-link {
    /* color: rgb(65, 60, 60);
    font-weight: 500; */
}

.bp-theme.blue.expander {
    background-color: #4596b4;
    color: #ffffff;
}

.bp-theme.default.expander {
    background-color: #484644;
    color: #ffffff;
}

.documents-title {
    background: #484644 !important;
    min-height: 200px;
    margin-top: 50px;
    width: 800px;
    text-align-last: center;
    width: 800px;
    margin-bottom: 30px;
}
.policy-docs .policy-name {
    font-weight: 800;
    color: #c8a774 !important;
    align-items: center;
    padding-top: 30px;
    /* font-size: 30px; */
    font-weight: 400;
    text-align-last: center;
}

.policy-docs .policy-no {
    font-weight: 800;
    color: ##484644 !important;
    align-items: center;
    padding-top: 30px;
    /* font-size: 15px; */
    font-weight: 400;
    background: white;
    margin: 10px;
    width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.all-documents-header {
    background: #c8a774 !important;

    /* font-size: 20px; */
    font-weight: 400;

    padding: 20px;
    color: white;
    text-decoration: ;
}

.plus-and-minus-toggle {
    color: #c8a774 !important;
    text-decoration: none;
    /* font-size: 50px; */
}
.gold-border {
    border: solid 1px #c8a774;
}
.gold-background {
    border: solid 1px #c8a774;
}

#taglist {
    display: inline;
    list-style: none;
}

#taglist li {
    display: inline;
}

#taglist li:after {
    content: ', ';
}

#taglist li:last-child:after {
    content: '';
}

#policy-docs-collection .hub-collection-row-even {
    background: #f3f2f1;
}
.dark {
    color: rgb(65, 60, 60);
}
.layout-title {
    color: rgb(65, 60, 60);
    /* font-size: 20px; */
    font-weight: 600;
}

.callout {
    position: relative;
    margin: 0px 10px;
    padding: 15px;
    background-color: #4596b4;
    color: white;
    /* easy rounded corners for modern browsers */
    border-radius: 8px;
}

.callout .notch-top {
    position: absolute;
    top: -10px;
    left: 20px;
    margin: 0;
    border-top: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #4596b4;
    padding: 0;
    width: 0;
    height: 0;
}

.callout .notch {
    position: absolute;
    top: 8px;
    left: -10px;
    margin: 0;
    border-top: 10px solid transparent;
    border-left: 0;
    border-right: 10px solid #4596b4;
    border-bottom: 10px solid transparent;
    padding: 0;
    width: 0;
    height: 0;
}

#journey_underwriteme .container-expandor {
    margin-top: 20px;
}

/* .ms-DetailsRow-cell {
    font-size: 25px !important;
    color: red;
    border: solid 1px red;
}
*/
/* 

const palette = {
    gold: '#c8a774',
    goldLight: '#ddc7a7',
    blue: '#4596b4',
    grey: '#484644',
    greyMedium: '#a19f9d',
    greyLight: '#f3f2f1',
    white: '#ffffff',
    black: '#000000',
    transparent: 'transparent',
};*/
