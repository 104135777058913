/* .title {
    font-weight: bolder;
    text-transform: capitalize;
} */

/* Used on demo home page */
/* #home-title {
    font-weight: bolder;
    font-size: 24px;
} */

.no-border {
  border-bottom: 0px !important;
}

.no-margins {
  margin: 0px;
  padding-top: 0px;
}

.no-margins-tb {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.no-margins-b {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.no-margins-t {
  margin-top: 0px;
  padding-top: 0px;
}

.no-margins-lr {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.text-align-right {
  text-align: right;
}

.minus8px {
  margin-left: -8px;
}

.hide-me {
  max-height: 120px;
  margin: 0px;
  padding: 0px;
}

.expander-content-items {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.expander-content {
  padding: 10px !important;
}

.red-text {
  color: red !important;
}

/* .border {
    border: 1px solid blue;
} */

/* .redborder,
.stack {
    border: 1px solid red;
}

.greenborder {
    border: 1px solid green;
}

.expander-content-items {
    border: 1px solid blue;
} */
