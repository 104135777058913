.context-panel-container {
  position: relative;
  height: 100%;
}

.context-panel-container::before {
  content: '';
  background-color: black;
  position: absolute;
  border: 100vh;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  opacity: 25%;
}
.context-panel {
  position: relative;
  max-width: 100%;
  background-color: white;
  /* height: calc(100% - 40px); */
  height: 100%;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.16);
  overflow-y: auto;
  min-width: 250px;
  /* top: 40px; */
}

.context-panel.full {
  height: 100%;
  width: 100%;
  left: 0;
}

.context-panel.left {
  left: 0;
}

.context-panel.right {
  right: 0;
}
.context-panel.middle,
.context-panel.centre {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-height: 100%;
  /* max-height: calc(100% - 40px); */
  min-height: 250px;
  max-width: 100%;
  height: initial;
}

.context-panel.top,
.context-panel.bottom {
  width: 100%;
  height: initial;
  max-height: 100%;
  min-height: 137px;
}

.context-panel.top {
  top: 0;
}

.context-panel.bottom {
  bottom: 0;
}

.context-panel > .close {
  position: absolute;
  right: 12px;
  top: 8px;
  /* font-size: 1.5em; */
  color: red;
}

.context-panel-header {
  padding: 10px;
  padding-top: 8px;
  /* padding-bottom: 20px; */
}

.context-panel-title {
  font-family: az_ea_font, 'Segoe UI', wf_segoe-ui_semibold, 'Segoe UI Semibold', 'Segoe WP Semibold', 'Segoe WP', Tahoma, Arial,
    sans-serif;
  font-weight: 600;
  /* font-size: 1.1em; */
  line-height: 15px;
  display: inline-block;
  color: black;
  max-width: 100%;
  padding-top: 5px;
}

.context-panel-subTitle,
.context-panel-description {
  padding-top: 2px;
  padding-bottom: 10px;
  max-width: 100%;
  /* font-size: 0.75em; */
  color: black;
  /* color: gray; */
  height: 0px;
}

.context-panel-body {
  height: 100%;

  padding: 10px;
}

.context-panel {
  display: flex;
  flex-direction: column;
}

.context-panel-wrapper {
  position: relative;
}
.context-panel-visible {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column nowrap;
  flex-flow: column nowrap;
  z-index: 100;
}

.dialog-body button {
  margin-top: 15px;
}
