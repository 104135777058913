/* https://piccalil.li/blog/a-modern-css-reset/ */
/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

:root {
  /* --ff-body: 'Pt sans', sans-serif; */
  /* --ff-body: 'Ariel', sans-serif;
    --ff-heading: 'Ariel', sans-serif; 
    ff-body: az_ea_font, 'Segoe UI', az_font, system-ui, -apple-system, BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell,
        'Open Sans', 'Helvetica Neue', sans-serif; */

  --brand-0: var(--green-0);
  --brand-1: var(--green-1);
  --brand-2: var(--green-2);
  --brand-3: var(--green-3);
  --brand-4: var(--green-4);
  --brand-5: var(--green-5);
  --brand-6: var(--green-6);
  --brand-7: var(--green-7);
  --brand-8: var(--grape-8);
  --brand-9: var(--green-9);

  --text0: var(--gray-0);
  --text1: var(--gray-1);
  --text2: var(--gray-2);
  --text3: var(--gray-3);
  --text4: var(--gray-4);
  --text5: var(--gray-5);
  --text6: var(--gray-6);
  --text7: var(--gray-7);
  --text8: var(--gray-8);
  --text9: var(--gray-9);

  --surface0: var(--gray-9);
  --surface1: var(--gray-8);
  --surface2: var(--gray-7);
  --surface3: var(--gray-6);
  --surface4: var(--gray-5);
  --surface5: var(--gray-4);
  --surface6: var(--gray-3);
  --surface7: var(--gray-2);
  --surface8: var(--gray-1);
  --surface9: var(--gray-0);

  --surface: var(--surface8);
  --surface-dark: var(--surface9);
  --surface-disabled: var(--surface5);

  --surface-invert-light: var(--surface4);
  --surface-invert: var(--surface2);
  --surface-invert-dark: var(--surface1);
  --surface-invert-disabled: var(--surface6);

  --text: var(--black);
  --text-invert: var(--white);
  --separator: var(--text4);
  --separator-invert: var(--text8);
  --border: var(--text7);
  --border-invert: var(--text2);

  --accent: var(--yellow-8);
  --brand: var(--cyan-8);
  --brand-invert: var(--cyan-2);
  --brand-dark: var(--cyan-9);
  --brand-light: rgba(12, 133, 153, 0.7);
  --brand-disabled: var(--cyan-4);
  /* --brand: #8a714c;
    --brand-dark: #5e4e35;
    --brand-disabled: rgba(138, 113, 76, 0.5); */

  --white: #fff;
  --black: #000;

  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  /* Dark */
  --clr-shades-600: #111111;

  --clr-light: #fff;
  --clr-dark: #000;

  --color: rgb(50, 49, 48);

  --portal-header-height: 40px;
  --portal-title-size: 45px;
  --portal-commandbar-size: 35px;
  --default-sidebar--width: 300px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding: 0;
}

.border-red {
  border: 1px solid red;
}

body {
  margin: 0;
  font-family: var(--font-sans);
  scrollbar-color: var(--brand) var(--gray-1);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: var(--font-size-4);
  overflow: hidden;
}

html,
body,
#root {
  height: 100%;
}

h1 {
  font-size: 2.25rem;
  font-weight: 600;
}

h2 {
  font-size: 2rem;
  font-weight: 700;
}

h3 {
  font-size: 1.75rem;
  font-weight: 700;
}

header button {
  color: var(--brand);
  background-color: var(--brand) !important;
}

header button:hover,
header .user-panel:hover {
  color: black !important;
  background-color: var(--brand-2) !important;
}

.portal__header--image {
  float: left;
  font-size: var(--fs-800);
  color: var(--clr-light);
  letter-spacing: 0.5rem;
  padding-left: 1.5rem;
}

.portal__header--image:hover {
  float: left;
  text-decoration: none;
  color: var(--clr-light);
  /* transform: scale(-1); */
  transform: rotate(-180deg);
  transition: 1000ms;

  /* transition: padding-left 900ms; */
}

.defaultlayout--container {
  height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header header'
    'side main ';
}

.defaultlayout--container__content {
  /* grid-area: main; */
}

.defaultlayout--container .sidebar {
  border-right: solid 1px var(--separator);
}

.defaultlayout--container header {
  grid-area: header;
}

.portal--container .side-menu {
  width: 300px;
}

/* Default menu color */
.side-menu li p {
  color: rgb(65, 60, 60);
}

.side-menu {
  background-color: var(--surface3);
  color: var(--text7);
}

.side-menu .title {
  border-bottom: 0.1em solid var(--text7);
}

/* .portal--sidebar,
.portal--sidebar .side-menu {
    min-width: min-content;
    overflow: hidden;
    background: transparent;
    color: #fff !important;
} */

.portal--sidebar {
  background: var(--surface) !important;
  color: var(--text) !important;
}

.portal--sidebar li p {
  color: var(--text-invert) !important;
}
.portal--sidebar .title {
  border-color: var(--text-invert) !important;
}

._portal__sidemenu {
  background: var(--background);
}

._portal__sidemenu_expanded {
  width: 250px;
}

.portal--content {
  min-height: 0;
}

.overflow-y {
  height: 100%;
  overflow-y: auto;
}

.hub-table {
  height: calc(100% - calc(var(--portal-header-height) + var(--portal-commandbar-size)));
  overflow-y: auto;
  margin: 0;
}

/* #XXXXXX > .hub-table {
  height: calc(100% - calc(var(--portal-header-height) + var(--portal-commandbar-size))) !important;
} */

.height100 {
  height: 100%;
}

.border {
  border: 1px solid green;
}

.alert {
  position: absolute;
  width: 100%;
  max-height: 100px;
  overflow-y: auto;
  z-index: 10;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row {
  display: flex;
  -mox-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 10px;
  background-repeat: no-repeat;
  background-position: 2px 2px;
  white-space: nowrap;
  cursor: pointer;
  touch-action: none;
}

.monaco-editor .suggest-widget .monaco-list .monaco-list-row > .contents > div {
  width: 100%;
}

/* Main div */
.loki {
  position: relative;
}

.loki-button {
  display: inline-flex;
  white-space: nowrap;
  color: var(--text-invert);
  justify-content: center;
  padding-inline: var(--size-4);
  padding-block: var(--size-2);
  background-color: var(--surface-invert);
  border-radius: 7px;
  border: var(--border-size-2) solid var(--surface);
  outline: none;
}

.loki-button.primary {
  background-color: var(--brand);
}

.loki-button:hover {
  background-color: var(--surface-invert-dark);
}

.loki-button.primary:hover {
  background-color: var(--brand-dark);
}

.loki-button:focus {
  box-shadow: 0px 0px 0px 1px var(--brand);
}

.loki-button.primary:hover {
  background-color: var(--brand-dark);
}

.loki-button:disabled,
.loki-button.primary.button:disabled {
  outline: none;
  background-color: rgba(150, 150, 150, 0.5);
  border: 0;
  /* 
    box-shadow: 0px 0px 0px 4px var(--brand); */
}

.loki-commandbar {
  background: var(--text-invert);
  font-size: 1.3rem;
}

.loki-commandbar .loki-button {
  height: 100%;
  background: var(--text-invert);
  color: var(--text);
  border-radius: 0;
  border: 0;
  font-weight: 500;
  padding-inline: var(--size-3);
  content: unset;
  margin-right: 1px;
}

.loki-commandbar__button .fa {
  color: var(--brand);
}

.loki-commandbar__button:hover {
  background: var(--surface);
  color: var(--text);
  border-top: 2px solid var(--surface);
  border-bottom: 2px solid var(--surface);
}

.loki-commandbar__separator {
  height: 20px;
  width: 1px;
  background-color: var(--separator);
}

.loki-commandbar__button:disabled,
.loki-commandbar__button:disabled .fa {
  color: var(--separator) !important;
  border-top: 2px solid var(--text-invert);
  border-bottom: 2px solid var(--text-invert);
  background-color: var(--text-invert);
}
/* 
.loki-table {
    border-collapse: collapse;
    margin: 5px 0;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 5px;
}

.loki-table thead tr {
    background-color: var(--brand-light);
    color: #ffffff;
    text-align: left;
    font-weight: 600;
    font-size: 1.75rem;
}

.loki-table th,
.loki-table td {
    padding: 5px 14px;
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
} */

.loki-table tbody tr {
  border-bottom: 1px solid #dddddd;
  position: relative;
}
/* 
.loki-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.loki-table tbody tr:last-of-type {
    border-bottom: 2px solid var(--separator);
}

.loki-table tbody tr.active-row {
    font-weight: bold;
    color: var(--separator);
}

#Dashboard .form-link {
    font-size: 1.65rem;
} */

.loki-table .docPanel {
  background: red;
  min-height: 190px;
  min-width: 350px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  bottom: 50px;
  background: var(--white);
  margin-top: -7rem;
  transform: translateX(-30%);
  border: 1px solid var(--border);
  border-radius: 10px;
  box-shadow: var(--shadow-4);
  border-collapse: collapse;
  max-width: 100%;
  table-layout: fixed;
}

.loki-table {
  max-width: 100%;
  /* table-layout: fixed; */
}

.loki-table tbody * + *,
.loki-table thead * + * {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  white-space: nowrap;
  /* text-align: center; */
}

.loki-table > thead > tr > th {
  padding: 0.385rem 0.75rem !important;
  border-spacing: 1rem;
}

.loki-table__branded thead {
  background: var(--brand);
  color: var(--white);
  margin: 5px;
}
.loki-table td {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* table {
    min-width: 100vw;
    width: auto;
    display: grid;
    border-collapse: collapse;
    grid-template-columns:
        minmax(150px, 1fr)
        minmax(150px, 1.67fr)
        minmax(150px, 1.67fr);
} */

tbody tr:hover {
  background: #eee;
}

[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
  /* opacity: 0; */
  visibility: hidden;
  z-index: 9;
  transition: 0.6s ease-in-out 0.4s;
}

[data-tooltip]:before {
  content: 'ccc';
  height: 0;
  width: 0;
  top: calc(100% - 9px);
  border-style: solid;
  border-width: 10px;
  border-color: transparent transparent #333 transparent;
}

[data-tooltip]:after {
  content: attr(data-tooltip);
  white-space: nowrap;
  background: #333;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 0.9rem;
  box-sizing: border-box;
  top: calc(100% + 11px);
  color: #fff;
  border-radius: 2px;
  text-shadow: 0px 1px 1px #000;
  z-index: 9;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  opacity: 1;
  visibility: visible;
  transition: 0.4s ease-in-out 0.3s;
}

/* 88888888888888888888888888888888888888 dynamic.css 8888888888888888888888888888888888 */
/*
*******************************
File: 404.css
*******************************
*/
#portal_404 h1 {
  color: var(--clr-primary-400);
}
/*
*******************************
File: areas.css
*******************************
*/
/* Add css here  */

/*
*******************************
File: cardsandfeatures.css
*******************************
*/
/* Add css here  */
.X {
  height: 0;
}

/*
*******************************
File: devassets.css
*******************************
*/
/* Add css here */
.dev-panel {
  position: absolute;
  bottom: 35px;
  right: 5px;
  min-width: 600px;
  min-height: 75vh;
  max-height: 75vh;
  height: 10%;
  background: var(--white);
  border: 1px solid var(--border);
  box-shadow: var(--shadow-3);
  border-radius: 5px;
  overflow-t: hidden;
}
.dev-tabs__user {
  padding: 1rem;
}

.footer-button {
  color: var(--white);
  height: 100%;
  padding: 3px;
}

.dev-tool-bar .footer-button:hover {
  background: var(--blue-5);
  border-radius: 2px;
  cursor: pointer;
}

/*
*******************************
File: extensions.css
*******************************
*/
/* Add css here  */

/*
*******************************
File: home.css
*******************************
*/
/* Add css here */
#Dashboard .hub-table .form-link {
  color: var(--brand);
  font-weight: 600;
}

.main-body {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'graph1 graph2'
    'decisions expiring'
    'latest latest';
  gap: 3rem;
}

@media screen and (max-width: 700px) {
  .home-graphs {
    display: none;
  }

  .main-body {
    grid-template-columns: 1fr;
    grid-template-areas: 'decisions' 'expiring' 'latest';
  }
}
/* ~ grid-template-columns: 1fr 
        # ~ grid-template-areas: "'graph1 graph2' 'decisions expiring' 'latest latest'  "    
        ~ grid-template-areas: "'graph1' 'graph2' 'decisions'  'expiring' 'latest'  "   */
/*
*******************************
File: layout.css
*******************************
*/
.nav_list {
  display: flex;
  flex-grow: 1;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav_list li:nth-of-type(1),
.nav_list li:nth-of-type(2) {
  margin-left: auto;
}

.notifications__container {
  position: absolute;
  right: 10px;
  top: 45px;
  min-height: 100px;
  max-height: calc(100% - 85px);
  min-width: 400px;
  max-width: 550px;
  border: 1px solid var(--border);
  box-shadow: var(--shadow-3);
  z-index: 10000;
  background: var(--white);
  overflow-y: auto;
  padding: 1.5rem;
}

.notifications__item * + * {
  content: '';
  padding-bottom: 0.75rem;
  border-bottom: solid 1px var(--separator);
  margin-bottom: 1.5rem;
}

.notifications__item:last-child {
  content: '';
  border: 0;
}
.notifications__title {
  font-size: 1.5rem;
  font-weight: bold;
}

.header-button {
  height: 100%;
  padding: 3px;
  color: var(--text-invert);
  font-size: 1.75rem;
  background: red;
}

.header-buttonn:hover {
  background: var(--blue-5);
  border-radius: 2px;
  cursor: pointer;
}

/*
*******************************
File: layouts.css
*******************************
*/
// delete me
/*
*******************************
File: organisations.css
*******************************
*/

/*
*******************************
File: pagebuilder.css
*******************************
*/
/* Add css hereYXZDX * /
ddddd
/*
*******************************
File: parentchilddetails.css
*******************************
*/
/* Add css here Q */
/* .searchbox-wrapper {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 3px;
    width: 230px;
} 

.search-details{
    margin: 8px;
    height: 100%;
}

.search-details-split{
    height: 100%;
}

.search-details-full{
    height: 100%;
    margin: 12px
}

.search-details-lhs{
    width: 250px;
    height: 100%;
}

.search-details-rhs{
    height: 100%;
    width: 100%;
    margin-left: 8px;
    box-shadow: -7px -3px 6px -6px lightgray;
    
}

  */
/*
*******************************
File: portal.css
*******************************
*/
.title {
  font-weight: bolder;
  text-transform: capitalize;
}

.no-margins {
  margin: 0px !important;
  padding: 0px !important;
}

.no-margins-tb {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.align-bottom {
  align-items: flex-end;
}

/*
*******************************
File: theme.css
*******************************
*/
/* :root {
    --brand: #8a714c;
    --brand-light: rgba(138, 113, 76, 0.8);
} */
.tooltip-container {
  width: 200px;
  overflow: hidden;
}

.tooltip-container .label {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  position: relative;
  cursor: pointer;
}

.tooltip-container .label:hover::after {
  content: attr(title); /* Use the title attribute as the tooltip text */
  position: absolute;
  bottom: 100%; /* Position above the label */
  left: 0;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: normal;
  max-width: 300px;
  z-index: 10;
  opacity: 1;
  visibility: visible;
}

.tooltip-container .label::after {
  /* Initial hidden tooltip styling */
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out;
}

