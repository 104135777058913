/* .hub-collection {
    list-style-type: none;
    display: inline-block;
    display: flex;
    flex-wrap: wrap;
} */

.feature-list__item {
    width: 100px;
    height: 100px;
    margin-right: 7px;
    cursor: pointer;
    padding: 0.8em 0;
    /* align-content: center; */
}

.card {
    border: 1px solid transparent;
}

.card:hover {
    border-color: rgba(204, 204, 204, 0.8);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
}

.feature-list,
.card-list {
    display: flex;
    flex-wrap: wrap;
}

.feature-list__item {
    width: 105px;
    height: 105px;
    margin-right: 7px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.feature-list__item:hover {
    color: var(--text);
    text-decoration: none;
}

.feature-list__item-icon-container {
    width: 100%;
    display: block;
    display: flex;
    justify-content: center;
    padding-top: 14px;
    padding-bottom: 10px;
}

.feature-list__item-icon {
    display: block;
    width: 32px;
}

.feature-list__item-title {
    text-align: center;
    color: black;
    font-weight: 600;
    /* font-size: 14px; */
}
.card-list__item {
    width: 251px;
    min-height: 100px;
    padding: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.card-list__item:hover {
    text-decoration: none;
}
.card-list__item-icon-container {
}
.card-list__item-icon {
    width: 36px;
    display: block;
}
.card-list__item-content-container {
    padding-left: 12px;
    /* font-size: 0.8em; */
}
.card-list__item-title,
.card-list__item-description {
    margin: 0;
}
.card-list__item-title {
    color: var(--text);
    font-weight: 600;
}
.card-list__item-description {
    color: var(--text);
    padding-top: 5px;
}
.card {
    border: 1px solid transparent;
}

.card:hover {
    border-color: rgba(204, 204, 204, 0.8);
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
}
