.question {
    border-bottom: solid 1px #bbbbbb;
    padding-top: 5px;
}

.question.hub-collection-row-last {
    border-bottom: solid 0px #bbbbbb;
    padding-top: 5px;
}

.question-type-ahead {
    padding: 8px 0;
}

.enquiry-line {
    background-color: #fbfbfb;
    margin-top: 10px;
    border-radius: 5px;
    border: solid 1px #bbbbbb;
    padding: 15px;
    margin-bottom: 20px;
}

.enquiry-line .preamble {
    border-bottom: solid 1px #bbbbbb;
    padding-bottom: 15px;
    /* font-size: 1.2em; */
    font-weight: 500px;
    color: rgb(65, 60, 60);
}

#journey_underwriteme label span {
    color: rgb(65, 60, 60);
}

#journey_underwriteme .expander-content {
    border: solid 1px #bbbbbb;
}

.toggle-button-active {
    background-color: #888888;
    border: 1px solid #888888;
    border-radius: 5px;
    margin-top: 5px;
    color: white;
    height: auto;
    padding: 10px 0;
}

.toggle-button,
.toggle-button:hover {
    background-color: white;
    color: #b6b6b7;
    border: 1px solid #b6b6b7;
    color: #b6b6b7;
    font-weight: 700;
    border-bottom: 2px solid #b6b6b7;
    border-radius: 5px;
    margin-top: 5px;
    height: auto;
    padding: 10px 0;
}

.toggle-button .ms-Button-label,
.toggle-button-active .ms-Button-label {
    line-height: 1;
}

.question-error {
    background-color: #ffdfdf;
}
