.form-product-background {
  background-color: #f3f3f3;
}

.form-expandor-background {
  background-color: #fafafa;
}

.no-background {
  background-color: white;
}
.form-product-header {
  font-weight: 500;
  /* font-size: 15px; */
}

.mt10 {
  margin-top: 10px;
}

.page-background {
  color: yellow;
}

.spacer5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.spacer20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.form-spacer {
  margin-top: 15px;
  margin-bottom: 15px;
}

.border {
  border: solid rgb(255, 238, 238) 1px;
}

.black {
  color: yellow;
}

.total {
  font-weight: 500;
  /* font-size: 20px; */
}

.totalPm {
  font-weight: 700;
  /* font-size: 25px; */
}

.pad4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.p-lr-5 {
  padding-left: 5px;
  padding-right: 5px;
}

.p-lr-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.p-lr-10 {
  padding-left: 10px;
  padding-right: 10px;
}
.p-lr-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.red-color {
  color: red;
}

.hide {
  display: none;
}

/* .no-wrap:{
    overflow: hidden;
} */

.uppercase {
  text-transform: uppercase;
}

.continueButton {
  margin-top: 25px;
}

.no-margins {
  margin: 0px;
  padding: 0px;
}

.no-top-margins {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.no-side-margins {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 0px;
  padding-right: 0px;
}

.ratechecker,
.conditions {
  font-weight: 700;
  background-color: #f0f0f0;
  padding: 5px;
}

.conditions {
  color: red;
  font-weight: 700;
  background-color: #f0f0f0 !important;
  padding: 5px;
}

.border {
  border: solid black 1px;
}

.ammendment {
  margin-top: 0px;
  margin-bottom: 10px;
  padding: 0px;
  width: 100%;
}

.mtb-5 {
  margin-top: 3px;
  margin-bottom: 3px;
  padding-top: 0px;
  padding-bottom: 0px;
  /* font-size: 15px; */
}
.black {
  color: black;
}

.horizontalpad {
  margin: 0px !important;
  padding: 0px !important;
}

.uw-side-margins {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 0px;
}

/* .sum{
    border-top: solid black 1px;
    border-bottom:  solid black 1px;
} */

.D {
  margin-top: 0px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  padding: 0px;
}

.conditions-header {
  font-weight: 700;
  /* font-size: 20px; */
}

.no-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
}

.jk-centre {
  margin-left: auto;
  margin-right: auto;
}
/* .no-margin {
    margin: 0px;
    padding: 0px;
} */

.no-padding {
  padding: 0px;
}

.Y {
  padding-left: 0px;
  padding-right: 0px;
  margin-right: -4px;
  margin-left: 0px;
}
.X {
  padding-left: 0px;
  padding-right: 0px;
  /* margin-right: -4px;  */
  margin-left: 0px;
}

.spacer20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.spacer10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.spacer5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.spacerTop20 {
  margin-top: 20px;
  margin-bottom: 0px;
}
.spacerBottom20 {
  margin-top: 0px;
  margin-bottom: 20px;
}

.notesHeight {
  height: 300px;
}

.parentWidth100::parent {
  background: red;
}

.p-tb-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.p-tb-1 {
  padding-top: 1px;
  padding-bottom: 1px;
}
.p-tb-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.p-tb-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-tb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.p-tb-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.hub-properties-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hub-properties-col-items {
  display: 'flex';
  align-items: 'center';
}

.quote-add-cover {
  margin: 8px !important;
}
.quote-summary-content {
  margin-left: 1px;
}

.deleteProduct {
  text-align: right;
  width: 100%;
  margin-top: -20px;
}

.float-right {
  float: right;
}

fieldset {
  margin: 8px;
  border: 1px solid silver;
  padding: 1rem;
  border-radius: 4px;
}
