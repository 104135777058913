/* Add css here Q */
.searchbox-wrapper {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 3px;
  width: 220px;
}

.search-details-lhs {
  width: 221px;
}

.search-box-container {
  margin: 3px;
  border: 1px solid var(--border);
  display: flex;
  align-items: baseline;
  padding-left: 3px;
  height: 29px;
  border-radius: 3px;
}

.search-box-container:focus-within {
  border: 2px solid rgb(0, 120, 212);
}

.search-box-container:focus-within .fa {
  display: none;
}

.search-box-container .fa {
  /* font-size: 1.3rem; */
}

.search-box-container .ms-TextField-fieldGroup::after {
  border: 0px solid rgb(0, 120, 212) !important;
}

.search-box > .ms-TextField-wrapper {
  padding: 0px;
  border: 0;
  outline: none;
}
.search-box .ms-TextField-fieldGroup {
  border: 0;
  height: 25px;
  outline: none;
}

.search-box input {
  border: 0;
  height: 25px;
  outline: 0;
}

.search-details-lhs .ms-DetailsList {
  overflow-x: hidden;
}

/* .searchbox-wrapper {
    width: 375px !important;
}
 */

.search-details {
  margin: 8px;
  height: 100%;
}

.search-details-split {
  height: 100%;
}

.search-details-full {
  height: 100%;
  margin: 12px;
}

.search-details-lhs {
  max-width: 450px;
  height: 100%;
}

.search-details-rhs {
  height: 100%;
  width: 100%;
  margin-left: 8px;
  box-shadow: -7px -3px 6px -1px lightgray;
}
