.menu-toggle {
  border: none;
  background: transparent;
  outline: none;
  width: 25px;
  min-width: 25px;
  height: 25px;
  min-height: 25px;
  line-height: 24px;
  padding: 0;
  /* font-size: 0.75em; */
}

.menu-toggle .menu-toggle {
  color: white;
  margin-left: auto;
  margin-right: 8px;
  margin-bottom: 5px;
}

.sidemenu-container {
  display: flex;
  height: calc(100vh - 128px);
}

.sidemenu-container .lhs {
}

.sidemenu-container .rhs {
  width: 100%;
}

.monaco-wrapper {
  height: 100%;
  width: calc(100% - 0px);
}

.commandbar-container {
  position: relative;
}

.commandbar-separator-container {
  height: 35px;
  display: flex;
  align-items: center;
  padding-right: 1px;
  padding-left: 1px;
}

.commandbar-separator {
  height: 60%;
  width: 1px;
  background-color: #d2d0ce;
}

.confirm-dialog {
  position: relative;
  width: 100%;
  top: 100%;
  left: 0;
  background-color: white;
  padding-top: 15px;
  -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
}

.confirm-dialog-title {
  color: black;
  font-size: large;
  padding-left: 20px;
}

.confirm-dialog-description {
  color: black;
  padding-left: 20px;
  padding-block: 10px;
}

.confirm-dialog-controls {
  border-top: 1px solid lightgrey;
  display: flex;
  flex-direction: row;
  padding: 18px 20px;
}

.confirm-dialog-control,
.react-confirm-alert-button-group > button {
  margin-right: 10px;
  min-width: 80px;
  height: 24px;
  line-height: 24px;
  border: 1px solid black;
  background-color: #fff;
  color: black;
  /* font-size: 0.75em; */
  border-radius: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0;
}
.confirm-dialog-control:hover,
.react-confirm-alert-button-group > button:hover {
  font-weight: 600;
  line-height: 22px;
  border: 2px solid #106ebe;
  background: #106ebe;
  color: white;
  box-shadow: inset 0px 0px 0px 1px white;
}

.content-separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: black;
}

.content-separator::before,
.content-separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid lightgrey;
}

.content-separator:not(:empty)::before {
  margin-right: 0.25em;
}

.content-separator:not(:empty)::after {
  margin-left: 0.25em;
}

.table-container {
  position: relative;
}
.table-container .ms-DetailsHeader {
  padding-top: 0;
}

.pointer {
  cursor: pointer;
}

.spacer {
  padding: 10px;
}
.spacer-x {
  padding-left: 10px;
  padding-right: 10px;
}

.spacer-y {
  padding-top: 10px;
  padding-bottom: 10px;
}
.spacer-table {
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.bp-panel {
}

.properties-container {
  width: 100%;
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  color: black;
}
@media screen and (max-width: 1065px) {
  .properties-container {
    flex-direction: column;
  }
  .properties-container li {
    width: 100% !important;
  }
}

.properties-container li {
  padding-bottom: 10px;
}

.ms-CommandBarItem-link {
  background-color: #fff !important;
}
.ms-CommandBarItem-link:hover {
  background-color: #f3f2f1 !important;
}

.ms-SearchBox-field {
  /* font-size: 0.8em; */
}

.ms-DetailsHeader-cellName {
  font-weight: bold;
}
.pivot-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.pivot-container div[role='tabpanel'] {
  flex: 1;
  max-height: calc(100% - 60px);
  padding-top: 10px;
}
.pivot-container div[role='presentation'] {
  padding-left: 2px;
  padding-right: 2px;
}
.pivot-container div[role='tabpanel'] .bp-portal-main-properties td {
  padding-left: 0;
  padding-right: 0;
}

.pivot-item {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.ace-editor {
  width: 100% !important;
  height: 100% !important;
}

.split-view {
}
.split-view-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.split-view-column {
  border-right: 1px solid lightgray;
  display: flex;
  flex-direction: column;
}
.split-view-column:last-child {
  border-right: none;
}

.tiles-title {
  color: black;
  margin: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}
/* 
ul {
    padding: 10px 10px;
} */

.all-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.tooltip-container {
  display: inline-block;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  vertical-align: top;
}

.p-b-0 {
  padding-bottom: 0;
}

.filepond--wrapper {
  width: 100%;
}
