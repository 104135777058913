.main-form {
  height: 100%;
}

.top-bar {
  padding: 0px;
  margin: 0px;
  height: 60px;
  width: 100%;
  border: 1px solid green;
}

.side-bar {
  padding: 0px;
  margin: 0px;
  height: 100%;
  background: #323130;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 10px 0 15px 0;
  overflow: hidden;
  position: relative;
}

.wrapper {
  display: flex;
  flex-flow: wrap;
}

/* We tell all items to be 100% width, via flex-basis */
.wrapper > * {
  flex: 2 100%;
  overflow: hidden;
}

.overflowauto {
  overflow: auto !important;
}

.overflowhidden {
  overflow: auto;
}

.header {
  display: flex;
  padding: 0px;
  align-items: center;
  background: var(--brand);
  height: 40px;
  display: flex;
  justify-content: space-between;
}

.header .button {
  background: var(--brand);
  height: 40px;
  display: flex;
  justify-content: space-between;
}

/* Medium screens */
@media all {
  .aside {
    flex: 1 auto;
    bottom: 0;
  }
}

/* Large screens */
@media all {
  .main {
    flex: 2 0px;
    overflow-x: hidden;
    overflow-y: auto;
    order: 2;
    height: 100%;
    width: calc(100% - 340px);
  }
  .aside-1 {
    order: 0;
    overflow-y: auto;
    max-width: fit-content;
    background-color: rgb(65, 60, 60);
  }
}

.header .left {
  height: 100%;
  flex: 0.6;
}

.header .middle {
  display: flex;
  justify-content: center;
}

.header .right {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.header .button {
  height: 100%;
  background: #484644;
  color: white;
}
.header .button:hover {
  margin-top: 2px;
  height: 100%;
  background: rgb(65, 60, 60);
  color: white;
}

#mainTemplate {
  padding: 0px;
}

.center-page {
  margin-left: auto;
  margin-right: auto;
  max-width: 950px;
}

.cursor-pointer {
  cursor: pointer;
}
.center-page-800 {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}
.width-1100 {
  max-width: 1100px;
}

.width-1000 {
  max-width: 1000px;
}

.horizontal-tab {
  background: white;
  margin: 10px 0;
  color: grey border;
}

@media all {
  .internal_aside {
    flex: 1 auto;
    flex-direction: row;
    bottom: 0;
    max-width: fit-content;
  }

  .internal_main {
    flex: 2 0px;
    overflow-x: hidden;
    overflow-y: auto;
    order: 2;
    height: 100%;
    width: calc(100% - 340px);
  }
  .internal_aside-1 {
    order: 0;
    overflow-y: auto;
    /* max-width: 300px;
        min-width: 300px; */
    border-right: 1px solid rgb(65, 60, 60);
  }
}

.monaco-wrapper {
  margin-top: 0.5em;
}

.expander-arrow {
  float: right;
  text-decoration: none;
  /* font-size: 14px; */
  padding: 8px;

  /* flex-direction: row; */
}

dynamic-aside::before {
  content: 'x';
  border: 3px solid red;
}

.flex-column {
  display: flex;
  flex-direction: column;
  height: 100%;
}
